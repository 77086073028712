import React, { ReactNode } from 'react';

import { ClassName, FetchItemsError, I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import { MainLayout } from '../MainLayout';

import { DefaultSecondaryMenu } from '../../../common/components/menus/DefaultSecondaryMenu';

import { MainScrollWrapper } from '../../helpers/MainScrollWrapper';
import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';

interface IndexLayoutProps {
  action: string;
  children: ReactNode;
  contentClassName?: ClassName;
  contentContainerClassName?: ClassName;
  customSecondaryMenu?: ReactNode;
  error?: FetchItemsError;
  header?: ReactNode | null;
  i18nTitle?: I18nText;
  onCloseSidebar?: () => void;
  popover?: ReactNode;
  sidebar?: ReactNode;
  subHeader?: ReactNode;
  title?: string;
  withScrollRestore?: boolean;
  withScrollToTop?: boolean;
  withoutActionError?: boolean;
  withoutHeader?: boolean;
}

function IndexLayout({
  action,
  children,
  contentClassName,
  contentContainerClassName,
  customSecondaryMenu,
  error,
  header,
  i18nTitle,
  onCloseSidebar,
  popover,
  sidebar,
  subHeader,
  title,
  withScrollRestore,
  withScrollToTop,
  withoutActionError,
  withoutHeader
}: IndexLayoutProps) {
  return (
    <MainLayout
      action={action}
      withoutActionError={withoutActionError}
      i18nTitle={i18nTitle}
      title={title}
      error={error}
    >
      <div className="flex flex-1">
        <div className="flex flex-col w-full z-0">
          {withoutHeader ? null : (
            <div
              id="page-header-wrap"
              className="relative z-5 flex-shrink-0 flex h-16 border-b border-gray-200 dark:border-gray-700"
            >
              {header}
              {popover}
            </div>
          )}

          <div className="relative flex-1 xl:z-0">
            <div className="absolute inset-0 flex overflow-hidden">
              <MainScrollWrapper
                withScrollRestore={withScrollRestore}
                withScrollToTop={withScrollToTop}
              >
                <div className={contentContainerClassName || 'pt-6 pb-8'}>
                  <div
                    className={contentClassName || 'flex flex-col space-y-8'}
                  >
                    {subHeader}
                    {children}
                  </div>
                </div>
              </MainScrollWrapper>

              {sidebar ? (
                <aside
                  id="side-panel"
                  className="fixed pb-10 sm:pb-0 top-0 right-0 xl:relative h-full shrink w-full xl:w-auto z-10"
                >
                  {onCloseSidebar ? (
                    <PureIconButtonHelper
                      className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md"
                      icon={IconsEnum.CROSS}
                      onClick={onCloseSidebar}
                    />
                  ) : null}
                  <div className="z-10 shadow-lg xl:shadow-none relative overflow-y-auto w-80 3xl:w-96 4xl:w-112 border-l border-gray-200 dark:border-gray-700 h-full bg-white dark:bg-gray-900 ml-auto">
                    {sidebar}
                  </div>
                  <div
                    id="side-panel-backdrop"
                    className="absolute inset-0 z-0 bg-gray-200 bg-opacity-30 dark:bg-gray-800 dark:bg-opacity-50 xl:hidden"
                    onClick={onCloseSidebar}
                  />
                </aside>
              ) : null}
            </div>
          </div>
        </div>
        {customSecondaryMenu || <DefaultSecondaryMenu />}
      </div>
    </MainLayout>
  );
}

export default IndexLayout;
